import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "./SLBreadcrumbs";
import { capitalize } from "utils/helpers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import NavMenu from "./NavMenu";
import { useCookies } from "react-cookie";
import { useRouteMatch } from "react-router-dom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const site = process.env.REACT_APP_SITE;
const storeId = process.env.REACT_APP_STORE_ID;
const enableNewUI = process.env.REACT_APP_NEWUI_LW;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;

export const MyBalanceDialog = ({
  open = false,
  setOpen,
  mealBalance = 0,
  mBalance = 0,
}) => {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>My Balance</DialogTitle>
      <DialogContent>
        <Typography component="h2" className="logoCenterBalance">
          <b>Meal Credit Available: </b>
          <b className="logoAmount">{mealBalance}</b> <br />
          <b>Current Balance: </b>
          <b className="logoAmount">${mBalance}</b>{" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: "15px" }}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function LogoCenter({
  selectedRevenueCenter,
  hasUserInfo = true,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const [mealBalance, setMealBalance] = useState();
  const [mealBalanceDollars, setMealBalanceDollars] = useState();
  const mBal = Math.abs(mealBalanceDollars / 100);
  const [openDialog, setOpenDialog] = useState(false);
  const mBalance = mBal.toFixed(2);
  const [firstName, setFirstName] = useState(
    capitalize(sessionStorage.getItem("firstName")?.toLowerCase() || "")
  );
  const [lastName, setLastName] = useState(
    capitalize(sessionStorage.getItem("lastName")?.toLowerCase() || "")
  );
  const [roomNumber, setRoomNumber] = useState(
    sessionStorage.getItem("roomNumber")
  );
  const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
  const [cookies] = useCookies();

  useEffect(() => {
    const url =
      loginCustomerNumber === "1"
        ? `https://ecommv2.servingintel.com/customer/login-customer-number/${serverId}/${siteId}/${firstName}/${roomNumber}`
        : `https://ecommv2.servingintel.com/customer/login/${serverId}/${siteId}/${storeId}/${firstName}/${roomNumber}`;
    axios
      .get(url)
      .then((response) => {
        const customer = response.data;
        if (
          customer.FirstName.toLowerCase() === firstName.toLowerCase() &&
          (customer.Address1.toLowerCase() === roomNumber.toLowerCase() ||
            customer.CustomerNumber == roomNumber)
        ) {
          setMealBalance(customer.MealCount);
          setMealBalanceDollars(
            customer.CreditLimit - customer.AvailableCredit
          );
        } else {
          window.location.href = site;
        }
      })
      .catch((error) => {});
  }, []);

  const useStyles = makeStyles((theme) => ({
    sLHeader: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.background.default,
    },
  }));
  const classes = useStyles();

  return (
    <div className="LogoMenuHeader">
      {enableNewUI === "1" ? (
        <div
          sx={{ display: "flex" }}
          className={classes.sLHeader}
          id="headerLogoSticky"
        >
          <Box
            id="NewUI-center"
            className="BannerImage"
            sx={{
              mx: "auto",
              width: "auto",
              textAlign: "center",
              fontSize: "0.875rem",
              fontWeight: "700",
              marginLeft: "auto !important",
              marginRight: "auto !important",
              order: 1,
              justifyContent: "flex-start",
            }}
          >
            <img
              className="NewUI-logo"
              src={siteMedSrc + "logo" + ".png"}
              alt={restaurantName}
            />
          </Box>
          <Breadcrumbs selectedRevenueCenter={selectedRevenueCenter} />
          <div className="userInfo">
            <Box
              id="transparentCustomUser"
              sx={{
                mx: "auto",
                width: "auto",
                textAlign: "left",
                fontSize: "0.875rem",
                fontWeight: "700",
                marginLeft: "38px",
                order: 2,
              }}
            >
              <Typography component="div" align="left">
                Good day <b className="logoAmount">{cookies.firstName}</b>. How
                can we serve you today?{" "}
              </Typography>
            </Box>
            <Box
              id="transparentCustomBal"
              sx={{
                mx: "auto",
                width: "auto",
                textAlign: "left",
                fontSize: "0.575rem",
                fontWeight: "700",
                marginLeft: "40px",
                marginRight: "73px",
                order: 3,
              }}
            >
              <Typography component="h2" className="logoCenterBalance">
                {/* <b>Meal Credit Available: </b>
            <b className="logoAmount">{mealBalance}</b> <br /> */}
                <b>Current Balance: </b>
                <b className="logoAmount">${mBalance}</b>{" "}
              </Typography>
            </Box>
          </div>
        </div>
      ) : (
        <>
          <MyBalanceDialog
            open={openDialog}
            setOpen={setOpenDialog}
            mealBalance={mealBalance}
            mBalance={mBalance}
          />
          <NavMenu firstName={firstName} lastName={lastName} />
          <div
            className="heroBannerImage"
            style={{
              marginTop: "80px",
              background: `linear-gradient(rgba(255, 255, 255, .05), rgba(0, 0, 0, .4)), url(${siteMedSrc}hero-image.jpg) no-repeat center center`,
            }}
          ></div>
          {hasUserInfo && (
            <>
              <Breadcrumbs selectedRevenueCenter={selectedRevenueCenter} />
              <div className="userInfo">
                <Box
                  id="transparentCustomUser"
                  sx={{
                    mx: "auto",
                    width: "max-content",
                    p: 1,
                    bgcolor: (theme) =>
                      theme.palette.mode === "dark" ? "#101010" : "grey.50",
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                    border: "1px solid",
                    borderColor: (theme) =>
                      theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                    borderRadius: 2,
                    textAlign: "left",
                    fontWeight: "700",
                    marginTop: 1,
                    marginBottom: "5px",
                    marginLeft: "38px",
                    order: 2,
                  }}
                >
                  <Typography component="div" align="left">
                    Good day <b className="logoAmount">{firstName}</b>. How can
                    we serve you today?{" "}
                  </Typography>
                </Box>
                <Box
                  id="transparentCustomBal"
                  sx={{
                    mx: "auto",
                    width: "max-content",
                    p: 1,
                    bgcolor: "transparent",
                    textAlign: "left",
                    fontWeight: "700",
                    marginTop: "5px",
                    marginBottom: "5px",
                    marginLeft: "32px",
                    order: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      maxWidth: "max-content",
                    }}
                  >
                    <button
                      className="MuiButton-root "
                      style={{
                        borderRadius: "15px !important",
                        padding: ".5rem 2rem",
                        width: "100%",
                        maxWidth: "max-content",
                        border: "1px solid",
                        borderColor: buttonText,
                        backgroundColor: buttonColor,
                        color: buttonText,
                        position: "relative",
                      }}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      <div
                        style={{
                          color: "#ffff",
                          fontWeight: "500",
                        }}
                      >
                        My Balance
                      </div>
                    </button>
                    {siteId == 73 && (
                      <button
                        className="MuiButton-root "
                        style={{
                          borderRadius: "15px !important",
                          padding: ".5rem 2rem",
                          width: "100%",
                          maxWidth: "max-content",
                          border: "1px solid",
                          borderColor: buttonText,
                          backgroundColor: buttonColor,
                          color: buttonText,
                          position: "relative",
                        }}
                        onClick={() => {
                          history.replace("/askme", {
                            from: history.location.pathname,
                          });
                        }}
                      >
                        <div
                          style={{
                            color: "#ffff",
                            fontWeight: "500",
                          }}
                        >
                          Ruby AI
                        </div>
                        <div
                          className={`pulse`}
                          style={{
                            background: "#ffff",
                            borderRadius: 0,
                            height: "110%",
                          }}
                        ></div>
                      </button>
                    )}
                  </Box>
                </Box>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
