import React, { useEffect } from "react";
import { CarouselItem, CarouselCaption, Carousel, img } from "react-bootstrap";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const titleSlider1 = process.env.REACT_APP_TITLE_SLIDER1;
const subtitleSlider1 = process.env.REACT_APP_SUBTITLE_SLIDER1;
const titleSlider2 = process.env.REACT_APP_TITLE_SLIDER2;
const subtitleSlider2 = process.env.REACT_APP_SUBTITLE_SLIDER2;
const titleSlider3 = process.env.REACT_APP_TITLE_SLIDER3;
const subtitleSlider3 = process.env.REACT_APP_SUBTITLE_SLIDER3;
const titleSlider4 = process.env.REACT_APP_TITLE_SLIDER4;
const subtitleSlider4 = process.env.REACT_APP_SUBTITLE_SLIDER4;
const titleSlider5 = process.env.REACT_APP_TITLE_SLIDER5;
const subtitleSlider5 = process.env.REACT_APP_SUBTITLE_SLIDER5;

export const HomepageSlider = () => {
  useEffect(() => {
    document.title = `${restaurantName}: ${tagline}`;
  }, []);

  return (
    <div>
      {enableKiosk === "1" ? (
        <a href="/menu">
          <Carousel fade style={{ marginTop: "0px" }} controls={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={siteMedSrc + "slider_img_A" + ".jpg"}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={siteMedSrc + "slider_img_B" + ".jpg"}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={siteMedSrc + "slider_img_C" + ".jpg"}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={siteMedSrc + "slider_img_D" + ".jpg"}
                alt="Fourth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={siteMedSrc + "slider_img_E" + ".jpg"}
                alt="Fifth slide"
              />
            </Carousel.Item>
          </Carousel>
        </a>
      ) : (
        <Carousel fade>
          <Carousel.Item>
            <img
              width="100%"
              height="100%"
              title={titleSlider1}
              className="d-block w-100"
              src={siteMedSrc + "header_img_1" + ".jpg"}
              alt={subtitleSlider1}
              loading="lazy"
            />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1 style={{ lineHeight: 1.1, color: textColor }}>
                  {titleSlider1}
                </h1>
                <p>{subtitleSlider1}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              width="100%"
              height="100%"
              title={titleSlider2}
              className="d-block w-100"
              src={siteMedSrc + "header_img_2" + ".jpg"}
              alt={subtitleSlider2}
              loading="lazy"
            />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1 style={{ lineHeight: 1.1, color: textColor }}>
                  {titleSlider2}
                </h1>
                <p>{subtitleSlider2}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              width="100%"
              height="100%"
              title={titleSlider3}
              className="d-block w-100"
              src={siteMedSrc + "header_img_3" + ".jpg"}
              alt={subtitleSlider3}
              loading="lazy"
            />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1 style={{ lineHeight: 1.1, color: textColor }}>
                  {titleSlider3}
                </h1>
                <p>{subtitleSlider3}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              width="100%"
              height="100%"
              title={titleSlider4}
              className="d-block w-100"
              src={siteMedSrc + "header_img_4" + ".jpg"}
              alt={subtitleSlider4}
              loading="lazy"
            />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1 style={{ lineHeight: 1.1, color: textColor }}>
                  {titleSlider4}
                </h1>
                <p>{subtitleSlider4}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              width="100%"
              height="100%"
              title={titleSlider5}
              className="d-block w-100"
              src={siteMedSrc + "header_img_5" + ".jpg"}
              alt={subtitleSlider5}
              loading="lazy"
            />
            <Carousel.Caption className="d-flex h-100 align-items-center justify-content-center">
              <div className="slider-caption">
                <h1 style={{ lineHeight: 1.1, color: textColor }}>
                  {titleSlider5}
                </h1>
                <p>{subtitleSlider5}</p>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      )}
    </div>
  );
};
