import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useAI from "../hooks/useAI";
import { useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie";
import wtn from "words-to-numbers";
import AIButton from "./AIButton";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ProductDetailsDialog from "./ProductDetailsDialog";
import ModifiersDialog from "./ModifiersDialog";
import {
  capitalize,
  describeItem,
  detectAffirmationOrDenial,
  detectCheckout,
  detectDone,
  detectRemove,
  detectSuggest,
  findClosestItem,
  findNumbersInString,
  fuseSearch,
  removeSpecialCharacters,
  suggestProduct,
} from "../utils/helpers";
import { TipsDrawer } from "../features/eComm";
import { useRouteMatch } from "react-router-dom";
import {
  updateConversationWithProduct,
  updateConversations,
} from "utils/services";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const siteName = process.env.REACT_APP_RESTAURANT_NAME;
const rubyAIUri = process.env.REACT_APP_RUBY_AI_URI;
const rubyConvoUri = process.env.REACT_APP_RUBY_CONVO_URI;

const greetings = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();

  if (hours >= 4 && hours < 12) {
    return "good morning";
  } else if (hours >= 12 && hours < 18) {
    return "good afternoon";
  } else {
    return "good evening";
  }
};
function AISpeech({ availableProducts = [] }) {
  const [cookieSIC, setCookieSIC] = useCookies([]);
  const [inputVal, setInputVal] = useState("");
  const [isTimeout, setIsTimeout] = useState(false);
  const [initOrder, setInitOrder] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openTips, setOpenTips] = useState(false);
  const senderId = localStorage.getItem("sender_id") || "default";
  const upToDate = localStorage.getItem("upToDate") || "";

  const [{ customerDetails }, setCookieSICName, removeCookieSICName] =
    useCookies(["customerDetails"]);
  const lastName = customerDetails?.LastName || "";
  const firstName = customerDetails?.FirstName || "";
  const middleName = customerDetails?.MiddleName || "";
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [prodDialogOpen, setProdDialogOpen] = useState(false);
  const [rubyIsSpeaking, setRubyIsSpeaking] = useState(false);
  const [rubyAnalyzing, setRubyAnalyzing] = useState(false);

  const match = useRouteMatch();

  const initMessage = match?.params?.category?.toLowerCase().includes("drinks")
    ? `Hello ${greetings()}${
        firstName ? ` ${capitalize(firstName)},` : ","
      } How about diving into a delightful coffee experience with our special brews today?`
    : `What can I get for you today${
        firstName ? `, ${capitalize(firstName)}?` : "?"
      }`;

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const [text, setText, loading, duration, stopSpeaking] = useAI();
  const timer = useRef(null);

  const { cartItems } = useSelector((state) => state.cart);
  const { orderStatus, cancelStatus, message, itemDetails } = useSelector(
    (state) => state.orderAI
  );
  const itemNames = useMemo(() => {
    return availableProducts.map((item) => {
      return item?.ItemName;
    });
  }, [availableProducts]);

  const dispatch = useDispatch();
  const [modifiers, setModifiers] = useState([]);
  const [availableModifiers, setAvailableModifiers] = useState([]);

  const callRasa = async () => {
    try {
      const { data } = await axios.post(`${rubyAIUri}/webhooks/rest/webhook`, {
        sender: senderId,
        message: finalTranscript,
      });

      setText(
        data[0]?.text ? data[0]?.text : "Sorry, I am not able to answer that."
      );

      if (!upToDate) {
        axios
          .put(`${rubyConvoUri}/conversations/add-details/` + senderId, {
            lastName,
            firstName,
            middleName,
            siteId,
            siteName,
          })
          .then((response) => {
            setCookieSIC("upToDate", true);
          })
          .catch((error) => {
            return;
          });
      }
    } catch (error) {
      setText(
        "We are unable to accept any new users at this time. Please try again later or check back when there is availability. Thank you for your understanding."
      );
    }
  };

  const addToCart = (quantity, product) => {
    const foundProduct = cartItems.find(
      (item) => item.ItemID === product.ItemID
    );
    let message = "";
    if (!foundProduct) {
      dispatch({
        type: "SET_CART",
        payload: [
          ...cartItems,
          {
            ...product,
            quantity: quantity,
          },
        ],
      });
      message = `${quantity} ${product?.ItemName} has been added to check`;
      updateConversationWithProduct(
        finalTranscript,
        message,
        { ...product, quantity },
        senderId
      );
    } else {
      const newCartItems = cartItems.map((item) => {
        if (item.ItemID === product.ItemID) {
          item.quantity = item.quantity + quantity;
        }
        return item;
      });

      dispatch({
        type: "SET_CART",
        payload: newCartItems,
      });

      message = `Additional ${quantity} ${product?.ItemName} has been added to check`;
      updateConversationWithProduct(
        finalTranscript,
        message,
        newCartItems,
        senderId
      );
    }

    return message;
  };

  const processOrder = async (product) => {
    if (product) {
      const {
        ItemID,
        ItemName,
        Department,
        ItemDescription,
        DefaultPrice,
        ItemMessages,
        FileName,
        allergens,
        dietaryCategories,
      } = product;
      const { data } = await axios.get(
        "https://ecommv2.servingintel.com/items/mods/" +
          siteId +
          "/" +
          ItemID +
          "?server_id=" +
          serverId
      );
      const newProduct = {
        ItemID,
        ItemName,
        Department,
        ItemDescription,
        DefaultPrice,
        ItemMessages,
        FileName,
        modifiers: data,
        comments: "",
        allergens,
        dietaryCategories,
        expiration: Date.now() + 86400000,
      };
      let message =
        data.length > 0
          ? `Please choose your preferred ${data[0]?.ItemDescription}, or say 'next' to explore other options.`
          : `How many ${newProduct?.ItemName} would you like to order?`;

      if (data.length > 0) {
        setAvailableModifiers([
          ...data,
          { ItemDescription: "quantity", ItemName: "quantity" },
        ]);
        setSelectedProduct(newProduct);
        setProdDialogOpen(true);
        dispatch({
          type: "ORDER_WITH_MODIFIERS",
          payload: {
            productDetails: newProduct,
            message: message,
          },
        });
      } else {
        dispatch({
          type: "INIT_ORDER",
          payload: {
            productDetails: newProduct,
            message: message,
          },
        });
      }
      await updateConversations(
        finalTranscript,
        message,
        senderId,
        "ask_question"
      );
      return;
    } else {
      setText("Sorry I didn't get that");
    }
  };

  const processItemWithModifiers = async (currentMod, nextMod) => {
    let done = detectDone(finalTranscript);
    let checkout = detectCheckout(finalTranscript);
    let numbers = findNumbersInString(finalTranscript);
    let subMods = currentMod?.sub_mods || [];
    let response = "";

    // Quantity
    if (numbers?.length === 1 && currentMod?.ItemName === "quantity") {
      const quantity = parseInt(wtn(numbers[0]));
      const message = addToCart(quantity, itemDetails);
      response = `${message}, Is there anything else that you want to order?`;
      await updateConversations(finalTranscript, response, senderId);

      dispatch({
        type: "SET_ORDER_SUGGESTION_QTY",
        payload: {
          quantity,
          message: response,
        },
      });

      // Close the dialog
      setProdDialogOpen(false);
      return;
    }

    // Immediate Checkout
    if (checkout || (done && currentMod?.ItemName === "quantity")) {
      // If immediate checkout make the cart items one
      const message = addToCart(1, itemDetails);
      response = `${message}, Is there anything else that you want to order?`;
      await updateConversations(finalTranscript, response, senderId);

      dispatch({
        type: "SET_ORDER_SUGGESTION_QTY",
        payload: {
          quantity: 1,
          message: response,
        },
      });

      // Close the dialog
      setProdDialogOpen(false);
      return;
    }
    //Proceed to done,

    if (done) {
      const updatedModifiers = availableModifiers.filter(
        (mod) => mod.ItemDescription !== currentMod?.ItemDescription
      );
      response =
        nextMod.ItemDescription === "quantity"
          ? `How many ${itemDetails?.ItemName} would you like to order?`
          : `Please choose your preferred ${nextMod?.ItemDescription}, or say 'next' to explore other options.`;
      await updateConversations(finalTranscript, response, senderId);
      setAvailableModifiers(updatedModifiers);

      dispatch({
        type: "ORDER_WITH_MODIFIERS",
        payload: {
          productDetails: itemDetails,
          message: response,
        },
      });
      return;
    }

    let { foundItem, highestScoreItem } = findClosestItem(
      finalTranscript,
      subMods.map((subMod) => subMod?.ItemDescription) || [],
      2
    );
    let remove = detectRemove(finalTranscript);
    let foundMod = subMods.find(
      (subMod) => subMod?.ItemDescription === highestScoreItem
    );

    const existing = modifiers.some(
      (mod) => mod.ItemDescription === foundMod?.ItemDescription
    );
    const newMods = [...modifiers];
    if (remove && foundMod) {
      if (existing) {
        response = `${foundMod?.ItemDescription} has been removed, try other item or say 'Next' to explore other options.`;
        await updateConversations(finalTranscript, response, senderId);
        itemDetails.modifiers = newMods.filter(
          (mod) => mod.ItemDescription !== foundMod?.ItemDescription
        );
        setModifiers((prev) =>
          prev.filter(
            (mod) => mod.ItemDescription !== foundMod?.ItemDescription
          )
        );
        dispatch({
          type: "ORDER_WITH_MODIFIERS",
          payload: {
            productDetails: itemDetails,
            message: response,
          },
        });

        return;
      } else {
        response = `The ${foundMod?.ItemDescription} ${currentMod?.ItemDescription} option has not yet been added, try other item or say 'Next' to explore other options.`;
        await updateConversations(finalTranscript, response, senderId);

        dispatch({
          type: "ORDER_WITH_MODIFIERS",
          payload: {
            productDetails: {
              ...itemDetails,
              modifiers: newMods.filter(
                (mod) => mod.ItemDescription !== foundMod?.ItemDescription
              ),
            },
            message: response,
          },
        });
        return;
      }
    }

    if (foundMod) {
      if (existing) {
        response = `${foundMod?.ItemDescription} is already added, try other item or say 'Next' to explore other options.`;
      } else {
        response = `${foundMod?.ItemDescription} is added, do you want to add other ${currentMod?.ItemDescription} or say 'Next' to explore other options.`;
        newMods.push(foundMod);
        setModifiers((prev) => [foundMod, ...prev]);
      }

      await updateConversations(finalTranscript, response, senderId);

      dispatch({
        type: "ORDER_WITH_MODIFIERS",
        payload: {
          productDetails: { ...itemDetails, modifiers: newMods },
          message: response,
        },
      });
      return;
    }

    if (cancelStatus === "pre-cancel") {
      await updateConversations(
        finalTranscript,
        `Sorry your order ${itemDetails?.ItemName} has been cancelled, try to order again.`,
        senderId
      );
      setProdDialogOpen(false);
      setSelectedProduct(null);
      dispatch({ type: "SET_CANCEL_ORDER" });
      return;
    }

    await updateConversations(
      finalTranscript,
      `I didn't get that, please try again one more time.`,
      senderId
    );
    dispatch({ type: "SET_PRE_CANCEL_ORDER" });
  };

  async function handleOrderCase() {
    let numbers = findNumbersInString(finalTranscript);
    if (numbers?.length === 1) {
      const quantity = parseInt(wtn(numbers[0]));
      const message = addToCart(quantity, itemDetails);
      await updateConversations(
        finalTranscript,
        `${message}, Is there anything else that you want to order?`,
        senderId
      );
      dispatch({
        type: "SET_ORDER_SUGGESTION_QTY",
        payload: {
          quantity,
          message: message + `, Is there anything else that you want to order?`,
        },
      });
    } else if (cancelStatus === "pre-cancel") {
      await updateConversations(
        finalTranscript,
        `Sorry your order ${itemDetails?.ItemName} has been cancelled, try to order again.`,
        senderId
      );
      dispatch({ type: "SET_CANCEL_ORDER" });
    } else {
      await updateConversations(
        finalTranscript,
        `I didn't get that, please try again one more time.`,
        senderId
      );
      dispatch({ type: "SET_PRE_CANCEL_ORDER" });
    }
    return;
  }

  async function handleAnythingElseCase(productItem) {
    if (productItem) {
      processOrder(productItem);
      return;
    }
    const intentAnythingelse = detectAffirmationOrDenial(finalTranscript);
    if (intentAnythingelse === "affirm") {
      await updateConversations(
        finalTranscript,
        "Great! Please tell me your order.",
        senderId
      );
      dispatch({
        type: "SET_END_ORDER_PROCESS",
        payload: { message: "Great! Please tell me your order." },
      });
    } else {
      await updateConversations(
        finalTranscript,
        "Alright! You can proceed now to checkout.",
        senderId
      );
      dispatch({
        type: "SET_END_ORDER_PROCESS",
        payload: {
          message: "Alright! You can proceed now to checkout.",
        },
      });
    }
  }

  const initOrderProcess = async () => {
    const suggestionMessage = suggestProduct(
      finalTranscript,
      availableProducts
    );
    await updateConversations(finalTranscript, suggestionMessage, senderId);
    setText(suggestionMessage);
    setInitOrder(false);
  };

  const aiOrderProcess = async () => {
    setRubyAnalyzing(true);
    try {
      SpeechRecognition.stopListening();

      const { foundItem, highestScoreItem: productItem } = findClosestItem(
        finalTranscript,
        availableProducts.map((item) => item?.ItemName),
        5,
        availableProducts
      );
      let hasDescription = describeItem(finalTranscript, foundItem);

      if (hasDescription && foundItem) {
        setText(hasDescription);
        await updateConversations(finalTranscript, hasDescription, senderId);
        return;
      }

      let hasSuggestion = detectSuggest(finalTranscript);
      if (hasSuggestion && orderStatus === null) {
        initOrderProcess();
        return;
      }
      if (!productItem && orderStatus === null) {
        callRasa();
        return;
      }
      // Consolidated switch-case for processing based on orderStatus
      switch (orderStatus) {
        case null:
          await processOrder(productItem);
          break;
        case "order-modifier":
          // Simplify modifier handling
          await processItemWithModifiers(
            availableModifiers[0],
            availableModifiers[1]
          );
          break;
        case "order":
          // Handle order case with a simplified logic
          await handleOrderCase();
          break;
        case "anythingelse":
          // Handle 'anything else' case with a simplified logic
          await handleAnythingElseCase(productItem);
          break;
        default:
          break;
      }
    } finally {
      setRubyAnalyzing(false); // Set the analyzing state to false when all operations are complete
    }
  };

  const handleClose = () => {
    setProdDialogOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_ORDER_AI" });
      resetTranscript();
    };
  }, []);

  useEffect(() => {
    console.log(message, "mess");
    if (!message) return;
    setText(message);
  }, [message, dispatch, setText]);

  useEffect(() => {
    if (!openTips && orderStatus === "tip-process") {
      dispatch({
        type: "SET_END_ORDER_PROCESS",
        payload: {
          message: `Thank you${
            cookieSIC?.sic_name ? ` ${capitalize(cookieSIC?.sic_name)},` : ","
          } you may proceed now to checkout`,
        },
      });
    }
  }, [openTips, orderStatus, dispatch, cookieSIC?.sic_name]);

  useEffect(() => {
    if (duration === 0) return;
    resetTranscript();
    setRubyIsSpeaking(true);
    const speakTimeout = setTimeout(() => {
      if (orderStatus === "order" && prodDialogOpen) return null;
      SpeechRecognition.startListening({ continuous: true });
      setRubyIsSpeaking(false);
    }, duration); // Add .5 sec to the duration

    return () => clearTimeout(speakTimeout);
  }, [duration, resetTranscript]);

  useEffect(() => {
    if (finalTranscript) {
      if (timer.current) {
        clearTimeout(timer.current);
        setIsTimeout(false);
      }

      timer.current = setTimeout(() => {
        setIsTimeout(true);
      }, 1000);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (transcript) {
      setInputVal(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (isTimeout) {
      aiOrderProcess();
    }
  }, [isTimeout]);

  useEffect(() => {
    console.log(selectedProduct?.modifiers, "updated");
  }, [selectedProduct?.modifiers?.[0]]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        bottom: 60,
        right: 60,
        zIndex: 2,
      }}
    >
      {selectedProduct && (
        <ProductDetailsDialog
          handleClose={handleClose}
          product={selectedProduct}
          open={prodDialogOpen}
          modifiers={modifiers}
          setModifiers={setModifiers}
        />
      )}
      <TipsDrawer open={openTips} setOpen={setOpenTips} />
      <AIButton
        SpeechRecognition={SpeechRecognition}
        listening={listening}
        inputVal={inputVal}
        text={text}
        setText={setText}
        initialMessage={initMessage}
        rubyIsSpeaking={rubyIsSpeaking}
        rubyAnalyzing={rubyAnalyzing}
      />
    </div>
  );
}

export default AISpeech;
